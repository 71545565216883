<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="اضافه جديد"
          icon="pi pi-plus"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('leagueAdd')"
          @click="$router.push('league/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          v-if="$checkRoles('leagueDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        />
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help" v-tooltip="'تحميل'"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="100"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه المسابقات

          <div style="text-align:left">
            <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="اختيار الاعمده"
              style="width: 20em"
            />
          </div>
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />

      <Column
        field="image"
        header="الشعار"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <img :src="$baseUploadURL + slotProps.data.image" class="dt-image" />
        </template>
      </Column>

      <Column
        v-for="(col, index) of selectedColumns"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="col.header"
        :sortable="true"
      />

      <Column
        field="status"
        header="الحاله"
        v-if="$checkRoles('leagueEdit')"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <InputSwitch
            v-model="slotProps.data.status"
            @change="updateStatus(slotProps.data.id)"
          />
        </template>
      </Column>

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('league/edit/' + slotProps.data.id)"
            icon="pi pi-pencil"
            v-if="$checkRoles('leagueEdit')"
            class="p-button-success p-ml-2  p-button-rounded"  v-tooltip="'تعديل'"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            v-if="$checkRoles('leagueDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded" v-tooltip="'حذف'"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      selectedColumns: [],
      columns: [
        {
          field: 'name',
          header: 'الاسم',
        },
        {
          field: 'location',
          header: 'المكان',
        },
      ],
      loading: true,
    };
  },
  methods: {
    updateStatus(id) {
      this.$http.get(`league/updateStatus/${id}`).then(
        () => {
          this.$toast.add({
            severity: 'success',
            summary: 'تم بنجاح',
            detail: 'تم التعديل بنجاح',
            life: 3000,
          });
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    getData() {
      this.$http.get(`league/withDeleted`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .post(`league/recoverList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.post(`league/recover/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>
